import { Component } from "vue-property-decorator";
import { Loading } from "@/ui/_components/Loading";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgDatePicker,
  DgSelect,
  DgFlex,
  DgGridContainer,
  DgHelper,
  DgRow,
  DgText,
  DgTextInput,
  IconName,
} from "@dasgate/uikit";
import {
  EnforcementResult,
  GetUserCredentialsResponse,
  PostEnforcement,
  UserInfo,
} from "@/legacy/features/portal/models/portal.models";
import { PagedResult } from "@/legacy/models/shared";
import FormMixin from "@/ui/_forms/formMixin";
import { documentNumberValidator, helpers, required } from "@/ui/_forms/validation";

export enum InterdictionsResultState {
  userNotFound = "user-not-found",
  granted = "granted",
  denied = "denied",
  indeterminate = "indeterminate",
}

@Component({
  components: {
    DgTextInput,
    DgButton,
    DgText,
    DgColumn,
    DgSelect,
    DgBox,
    DgRow,
    DgDatePicker,
    DgHelper,
    DgFlex,
    DgGridContainer,
    Loading,
  },
})
export default class CheckInterdictions extends FormMixin<"docNumber" | "birthDate" | "name" | "surname" | "docType"> {
  public result: InterdictionsResultState = InterdictionsResultState.indeterminate;
  public reason = "";
  public anonymous = false;
  public showResult = false;
  private credentials: GetUserCredentialsResponse = {} as GetUserCredentialsResponse;
  private user: UserInfo = {} as UserInfo;

  initForm() {
    return {
      initialValues: {
        docNumber: "",
        birthDate: "",
        name: "",
        surname: "",
        docType: "id_card",
      },
      rules: {
        docNumber: {
          required: helpers.withMessage(this.$t("common.errors.required").toString(), required),
          documentFormat: helpers.withMessage(
            this.$t("common.errors.document_format").toString(),
            documentNumberValidator
          ),
        },
        birthDate: {
          required: helpers.withMessage(this.$t("common.errors.required").toString(), required),
        },
      },
    };
  }

  public get useExtendedInterdictionForm(): boolean {
    return this.$appStore.getters.settings.extendedInterdictionForm || false;
  }

  public get docTypeOptions() {
    return [
      { label: this.$t("common.fields.dni-nie"), value: "id_card" },
      { label: this.$t("common.fields.passport"), value: "passport" },
    ];
  }

  get resultState() {
    const resultStatusMap: { [key in InterdictionsResultState]: "success" | "error" | "warning" | "info" } = {
      [InterdictionsResultState.userNotFound]: "error",
      [InterdictionsResultState.granted]: "success",
      [InterdictionsResultState.denied]: "error",
      [InterdictionsResultState.indeterminate]: "warning",
    };
    return resultStatusMap[this.result];
  }

  get resultIcon(): IconName {
    const resultIconMap: { [key in InterdictionsResultState]: IconName } = {
      [InterdictionsResultState.userNotFound]: "crossOutline",
      [InterdictionsResultState.granted]: "checkOutline",
      [InterdictionsResultState.denied]: "notAllowed",
      [InterdictionsResultState.indeterminate]: "help",
    };
    return resultIconMap[this.result];
  }

  public get isLoading(): boolean {
    return this.$appStore.getters.loading;
  }

  get resultMessage() {
    let message = this.$t(
      "common.portal.check-interdictions.results." + (this.anonymous ? "anonymous-" : "") + this.result,
      {
        username: this.user.username,
      }
    );
    if (this.reason.trim() === "underaged") {
      message = this.$t("common.portal.check-interdictions.results.underaged");
    }
    return message as string;
  }

  public async searchUser() {
    this.credentials = {} as GetUserCredentialsResponse;
    this.user = {} as UserInfo;
    const searchedUser: PagedResult<UserInfo> = await this.$services.userService.getUsers({
      pageSize: 10,
      pageIndex: 1,
      bookmark: "",
      orderby: "date_register",
      sorted: "DESC",
      idNumber: this.$form.model.docNumber,
      birthDate: this.$form.model.birthDate,
    });
    if (searchedUser.items.length !== 1) {
      if (this.$appStore.getters.canEnforceInExternalSystems) {
        await this.enforceAnonymousUser();
      } else {
        this.anonymous = false;
        this.result = InterdictionsResultState.userNotFound;
      }
    } else {
      await this.enforceUser(searchedUser);
    }
    this.showResult = true;
  }

  private async enforceUser(searchedUser: PagedResult<UserInfo>) {
    this.anonymous = false;
    this.user = searchedUser.items[0];
    this.credentials = await this.$services.userService.getCredentials({
      id: this.user.id,
    });
    const enforcement = this.buildEnforcement();
    const enforcementResult: EnforcementResult = await this.$services.userService.enforceUser(enforcement);
    this.result = InterdictionsResultState[enforcementResult.result];
    this.reason = enforcementResult.reason;
  }

  private buildEnforcement() {
    return {
      timestamp: new Date().toISOString(),
      subject: this.user.id,
      object: this.$appStore.getters.userLocation,
      action: "access",
      claims: this.credentials.metadata,
    } as PostEnforcement;
  }

  private async enforceAnonymousUser() {
    this.anonymous = true;
    const enforcement = this.buildAnonymousEnforcement();
    const enforcementResult: EnforcementResult = await this.$services.userService.enforceUser(enforcement);
    this.result = InterdictionsResultState[enforcementResult.result];
    this.reason = enforcementResult.reason;
  }

  private buildAnonymousEnforcement() {
    const claims: { [key: string]: any } = {
      id_number: this.$form.model.docNumber,
      birth_date: this.$form.model.birthDate,
      document_type: this.$form.model.docType,
    };

    if (this.$form.model.docType === "passport") {
      claims.document_number = this.$form.model.docNumber;
    }

    if (this.$form.model.name) {
      claims.name = this.$form.model.name;
    }

    if (this.$form.model.surname) {
      claims.surname = this.$form.model.surname;
    }

    return {
      timestamp: new Date().toISOString(),
      subject: "anonymous",
      object: this.$appStore.getters.userLocation,
      action: "access",
      claims: claims,
    } as unknown as PostEnforcement;
  }
}
