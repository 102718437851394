import Component, { mixins } from "vue-class-component";
import {
  DgButton,
  DgColumn,
  DgDatePicker,
  DgFlex,
  DgModal,
  DgSelect,
  DgText,
  DgTextInput,
  DgWindowSizeMixin,
  SelectOption,
} from "@dasgate/uikit";
import { AccessItem } from "./_components/AccessItem";
import { Access } from "@/legacy/features/portal/models/portal.models";
import { PagedResult } from "@/legacy/models/shared";
import { AccessesList } from "@/ui/Portal/AuditAccesses/_components/AccessesList";
import FormMixin from "@/ui/_forms/formMixin";
import { helpers, required } from "@/ui/_forms/validation";
import { isEmpty } from "@/core/shared/utils";
import { downloadFile } from "@/ui/_utils/downlaodFile";
import moment from "moment";

@Component({
  components: {
    AccessesList,
    AccessItem,
    DgColumn,
    DgText,
    DgSelect,
    DgDatePicker,
    DgTextInput,
    DgButton,
    DgModal,
    DgFlex,
  },
})
export default class AuditAccesses extends mixins(
  DgWindowSizeMixin,
  FormMixin<"facility" | "accessDate" | "docNumber">
) {
  public facilitiesResult: { [key: string]: number } = {};
  public accessesPagedResult: PagedResult<Access> = {
    bookmark: "",
    pageSize: 10,
    totalPages: -1,
    totalItems: -1,
    items: [],
  };
  public bookmarks = [""];
  public pageIndex = 1;
  public password = "";
  public isModalOpen = false;

  public async mounted() {
    await this.getFacilities();
  }

  initForm() {
    return {
      initialValues: {
        facility: "",
        accessDate: "",
        docNumber: "",
      },
      rules: {
        facility: {
          required: helpers.withMessage(this.$t("common.errors.required").toString(), required),
        },
      },
    };
  }

  public get loading(): boolean {
    return this.$appStore.getters.loading;
  }

  get facilities() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(this.facilitiesResult).map(([name, _]) => ({ name, value: name }));
  }

  get facilityOptions(): SelectOption[] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return this.facilities.map(({ name, value }) => ({
      label: name.split("/").slice(-1)[0],
      value: name,
    }));
  }

  get selectedFacility() {
    return this.$form.model.facility;
  }

  get selectedDateInterval() {
    if (isEmpty(this.$form.model.accessDate)) {
      return {
        fromDate: undefined,
        toDate: undefined,
      };
    }

    return {
      fromDate: moment(this.$form.model.accessDate).toISOString(),
      toDate: moment(this.$form.model.accessDate).add(1, "day").subtract(1, "millisecond").toISOString(),
    };
  }

  get showResult() {
    return this.accessesPagedResult.totalPages > 0;
  }

  public async showModal(): Promise<void> {
    this.isModalOpen = true;
  }

  public cancelDownloadRequest() {
    this.password = "";
    this.isModalOpen = false;
  }

  public async downloadAccessesZip() {
    this.isModalOpen = false;

    const response = await this.$services.accessesService.downloadAccesses({
      password: this.password,
      id_number: this.$form.model.docNumber,
      location: this.selectedFacility,
      from_date: this.selectedDateInterval.fromDate,
      to_date: this.selectedDateInterval.toDate,
    });

    const zipName = "accesses_" + this.selectedFacility;
    const blob = new Blob([response.data], { type: "application/zip" });
    downloadFile(blob, zipName);
  }

  private async getAccesses(pageIndex: number) {
    this.pageIndex = pageIndex;

    this.accessesPagedResult = await this.$services.accessesService.auditAccesses({
      pageSize: 10,
      orderby: "created_at",
      sorted: "DESC",
      bookmark: this.bookmarks[pageIndex - 1],
      pageIndex,
      idNumber: this.$form.model.docNumber,
      location: this.selectedFacility,
      fromDate: this.selectedDateInterval.fromDate,
      toDate: this.selectedDateInterval.toDate,
    });

    this.bookmarks[this.pageIndex] = this.accessesPagedResult.bookmark;
  }

  private async getFacilities() {
    const accessLevel = this.$appStore.getters.settings.auditAccessesLevel;
    const firstRequestData = {
      pageSize: 10,
      pageIndex: 1,
      bookmark: "",
      counts: [accessLevel],
      location: this.$appStore.getters.userLocation,
    };
    const response = await this.$services.accessesService.auditAccesses(firstRequestData);
    this.facilitiesResult = response.counts["facility"];
  }
}
