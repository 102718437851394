import Component from "vue-class-component";
import { DgButton, DgColumn, DgFlex, DgText } from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import Vue from "vue";

@Component({
  components: {
    DgButton,
    DgColumn,
    DgFlex,
    DgText,
  },
})
export default class ResetCredentials extends Vue {
  public cancel() {
    this.$router.back();
  }

  public goToDeleteAccount(): void {
    this.$router.push({ name: RouteNames.DeleteAccount, params: { userId: this.$appStore.getters.userId } });
  }
}
