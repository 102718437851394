import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Access } from "@/legacy/features/portal/models/portal.models";
import {
  DgBox,
  DgColumn,
  DgDivider,
  DgFlex,
  DgGridContainer,
  DgIcon,
  DgRow,
  DgText,
  DgTextButton,
  DgWindowSizeMixin,
} from "@dasgate/uikit";

@Component({ components: { DgGridContainer, DgText, DgTextButton, DgDivider, DgColumn, DgRow, DgIcon, DgBox, DgFlex } })
export default class AccessItem extends DgWindowSizeMixin {
  @Prop({ required: true }) public access!: Access;

  public expanded = false;

  get classes() {
    return [this.access.accepted ? "--accepted" : "--denied"];
  }

  get checkedSource() {
    return this.access.entity.watchlistsCheckMode === "offline"
      ? this.access.entity.watchlistsChecked.join(", ")
      : this.access.entity.enforcementSource;
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
