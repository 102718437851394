import Component from "vue-class-component";
import { has } from "@/core/shared/utils";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgDatePicker,
  DgFlex,
  DgHelper,
  DgRow,
  DgText,
  DgTextButton,
  DgTextInput,
} from "@dasgate/uikit";
import { GetUserCredentialsResponse, UserInfo } from "@/legacy/features/portal/models/portal.models";
import { PagedResult } from "@/legacy/models/shared";
import { Loading } from "@/ui/_components/Loading";
import FormMixin from "@/ui/_forms/formMixin";
import { documentNumberValidator, helpers, required } from "@/ui/_forms/validation";

@Component({
  components: {
    DgRow,
    DgColumn,
    DgText,
    DgTextInput,
    DgTextButton,
    DgButton,
    DgFlex,
    DgDatePicker,
    DgHelper,
    DgBox,
    Loading,
  },
})
export default class ActivateUsers extends FormMixin<"docNumber" | "birthDate"> {
  public showResult = false;
  public credentials: GetUserCredentialsResponse = {} as GetUserCredentialsResponse;
  public user: UserInfo = {} as UserInfo;

  initForm() {
    return {
      initialValues: {
        docNumber: "",
        birthDate: "",
      },
      rules: {
        docNumber: {
          required: helpers.withMessage(this.$t("common.errors.required").toString(), required),
          documentFormat: helpers.withMessage(
            this.$t("common.errors.document_format").toString(),
            documentNumberValidator
          ),
        },
        birthDate: {
          required: helpers.withMessage(this.$t("common.errors.required").toString(), required),
        },
      },
    };
  }

  get helperMessage(): string {
    if (!has(this.user, "username")) return this.$t("common.portal.activate-users.user-not-found") as string;
    else if (has(this.user, "username") && Object.keys(this.credentials).length !== 0 && this.locationActive)
      return this.$t("common.portal.activate-users.user-already-active", {
        location: this.assistantScope,
      }) as string;
    else return "" as string;
  }

  public get isLoading(): boolean {
    return this.$appStore.getters.loading;
  }

  public get assistantScope(): string {
    const assistantLocation = this.$appStore.getters.userLocation;
    return assistantLocation.split("/")[1];
  }

  public get locationActive(): boolean {
    const assistantLocation = this.$appStore.getters.userLocation;
    if (assistantLocation == null || this.credentials.locations == null) {
      return true;
    }
    for (const entry of this.credentials.locations) {
      if (assistantLocation.includes(entry)) {
        return true;
      }
    }
    return false;
  }

  public async searchUser() {
    this.credentials = {} as GetUserCredentialsResponse;
    this.user = {} as UserInfo;
    const searchedUser: PagedResult<UserInfo> = await this.$services.userService.getUsers({
      pageSize: 10,
      pageIndex: 1,
      bookmark: "",
      orderby: "date_register",
      sorted: "DESC",
      idNumber: this.$form.model.docNumber,
      birthDate: this.$form.model.birthDate,
    });
    if (searchedUser.items.length === 1) {
      this.credentials = await this.$services.userService.getCredentials({
        id: searchedUser.items[0].id,
      });
      this.user = searchedUser.items[0];
    }
    this.showResult = true;
  }

  public async addRights() {
    this.credentials = await this.$services.userService.addNewLocation({
      id: this.user.id,
      location: this.$appStore.getters.userLocation,
    });
  }
}
