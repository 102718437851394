import { render, staticRenderFns } from "./AssistantAccountCancellation.vue?vue&type=template&id=f5f24db2&scoped=true&"
import script from "./AssistantAccountCancellation.ts?vue&type=script&lang=ts&"
export * from "./AssistantAccountCancellation.ts?vue&type=script&lang=ts&"
import style0 from "./AssistantAccountCancellation.scss?vue&type=style&index=0&id=f5f24db2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f24db2",
  null
  
)

export default component.exports