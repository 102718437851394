import { render, staticRenderFns } from "./CheckInterdictions.vue?vue&type=template&id=507f9bc5&scoped=true&"
import script from "./CheckInterdictions.ts?vue&type=script&lang=ts&"
export * from "./CheckInterdictions.ts?vue&type=script&lang=ts&"
import style0 from "./CheckInterdictions.scss?vue&type=style&index=0&id=507f9bc5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507f9bc5",
  null
  
)

export default component.exports