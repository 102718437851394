import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import {
  DgColumn,
  DgFlex,
  DgGridContainer,
  DgSeparator,
  DgText,
  DgTextButton,
  DgWindowSizeMixin,
} from "@dasgate/uikit";
import { List } from "@/ui/_components/List";
import { AccessItem } from "@/ui/Portal/AuditAccesses/_components/AccessItem";
import { Access } from "@/legacy/features/portal/models/portal.models";

@Component({ components: { DgColumn, DgFlex, DgText, DgTextButton, List, DgSeparator, DgGridContainer, AccessItem } })
export default class AccessesList extends DgWindowSizeMixin {
  @Prop({ required: true }) public accesses!: Access[];
  @Prop({ required: true }) public totalPages!: number;
  @Prop({ required: true }) public currentPage!: number;
  @Prop({ required: true }) public isLoading!: boolean;

  @Emit() onPageRequest(index: number) {
    return index;
  }

  @Emit() onDownloadZipRequest() {
    return;
  }
}
