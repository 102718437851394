import Component from "vue-class-component";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgDatePicker,
  DgFlex,
  DgHelper,
  DgRow,
  DgText,
  DgTextButton,
  DgTextInput,
} from "@dasgate/uikit";
import { Loading } from "@/ui/_components/Loading";
import { GetUsersRequest, UserInfo } from "@/legacy/features/portal/models/portal.models";
import { PagedResult } from "@/legacy/models/shared";
import { isUndefined } from "lodash";
import { RouteNames } from "@/ui/_router";
import Vue from "vue";

@Component({
  components: {
    DgRow,
    DgColumn,
    DgText,
    DgBox,
    DgTextInput,
    DgTextButton,
    DgButton,
    DgFlex,
    DgDatePicker,
    DgHelper,
    Loading,
  },
})
export default class AssistantAccountCancellation extends Vue {
  public filterdata = {
    birthDate: undefined,
    idNumber: "",
    pageSize: 10,
    pageIndex: 1,
    bookmark: "",
    orderby: "date_register",
    sorted: "DESC",
  } as GetUsersRequest;
  public showResult = false;
  public isUserNotFound = false;
  public userName = "";
  public user: UserInfo = {} as UserInfo;
  public idNumberValidationError = "";

  public get canSearchUsers(): boolean {
    return this.filterdata.idNumber !== "" && this.filterdata.birthDate !== undefined;
  }

  public async searchUser() {
    this.user = {} as UserInfo;
    const searchedUser: PagedResult<UserInfo> = await this.$services.userService.getUsers(this.filterdata);
    this.showResult = true;
    if (searchedUser.items.length !== 1) {
      this.isUserNotFound = true;
    } else {
      this.user = searchedUser.items[0];
      this.isUserNotFound = false;
      this.userName = this.user.username;
    }
  }

  public get isLoading(): boolean {
    return this.$appStore.getters.loading;
  }

  public validateDocNumber(idNumber: string | undefined) {
    if (!isUndefined(idNumber)) {
      this.filterdata.idNumber = idNumber.toUpperCase();
    }
  }

  public goToDeleteAccount(): void {
    this.$router.push({
      name: RouteNames.DeleteAccount,
      params: { userId: this.user.id, username: this.user.username },
    });
  }
}
